// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import "./src/styles/global.css";

import React from "react";
import { MantineProvider } from "@mantine/core";
import { theme } from "./src/theme";
import Layout from "./src/components/Layout";

export const wrapPageElement = ({ element }: { element: React.ReactNode }) => {
  return (
    <MantineProvider>
      <Layout>{element}</Layout>
    </MantineProvider>
  );
};
