import * as React from "react";
import { Group, Burger, Container, Box, Flex, Text } from "@mantine/core";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const links = [
  { link: "/about", label: "About" },
  // { link: "/blog", label: "Blog" },
  { link: "/contact", label: "Contact" },
  // { link: "/now", label: "Now" },
];

export const NavBar: React.FC<{
  opened: boolean;
  handleClick: () => void;
  handleToggle: () => void;
}> = ({ opened, handleToggle, handleClick }) => {
  const items = links.map((link) => {
    return (
      <Link
        className="no-underline text-inherit flex cursor-pointer items-center rounded-base border-solid border-black border-2 bg-bg px-6 py-1 font-bold shadow-base transition-all hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none"
        key={link.label}
        to={link.link}
        onClick={handleClick}
      >
        {link.label}
      </Link>
    );
  });

  const mobileItems = links.map((link) => {
    return (
      <Link
        className="no-underline w-full text-inherit justify-center flex cursor-pointer items-center rounded-base border-solid border-black border-2 bg-bg px-6 py-1 font-bold shadow-base transition-all hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none"
        key={link.label}
        to={link.link}
        onClick={handleClick}
      >
        {link.label}
      </Link>
    );
  });

  return (
    <>
      <header className="border-solid border-black border-2 mt-2 h-14 bg-bg">
        <Container
          className="flex justify-between items-center h-full"
          size="lg"
        >
          <Link
            key="miaskowski.pl"
            className="no-underline text-inherit flex cursor-pointer items-center rounded-base px-2 py-1 font-bold"
            to="/"
            onClick={handleClick}
          >
            {/*<StaticImage*/}
            {/*  src="../images/svg/leliwa-sprawiedliwa-stempel-czarny.svg"*/}
            {/*  alt="my_logo"*/}
            {/*  placeholder="tracedSVG"*/}
            {/*  layout="fixed"*/}
            {/*  width={40}*/}
            {/*  height={40}*/}
            {/*/>*/}
            <Text className="font-bold font-mono">miaskowski.pl</Text>
          </Link>
          <Group gap={5} visibleFrom="sm">
            {items}
          </Group>
          <Box
            className="flex cursor-pointer items-center rounded-base border-solid border-black border-2 bg-main px-6 py-1 font-bold shadow-base transition-all hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none"
            visibleFrom="sm"
          >
            <Box
              component="a"
              download="Michał Miąskowski - Software Engineer resume"
              key="resume"
              className="no-underline text-inherit"
              href="/resume.pdf"
            >
              My Resume
            </Box>
            <FontAwesomeIcon className="pl-2" icon={faDownload} />
          </Box>
          <Burger
            transitionDuration={10}
            opened={opened}
            onClick={handleToggle}
            size="sm"
            hiddenFrom="sm"
          />
        </Container>
      </header>
      {opened && (
        <Flex className="items-center justify-center p-4 flex-col gap-y-12 h-[89.5vh] mt-2 border-solid border-2 border-black">
          <Text fw={700} size="xl">
            MENU
          </Text>
          {mobileItems}
          <Box className="flex w-full justify-center cursor-pointer items-center rounded-base border-solid border-black border-2 bg-main px-6 py-1 font-bold shadow-base transition-all hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none">
            <Box
              component="a"
              download="Michał Miąskowski - Software Engineer resume"
              key="resume"
              className="no-underline text-inherit"
              href="/resume.pdf"
            >
              My Resume
            </Box>
            <FontAwesomeIcon className="pl-2" icon={faDownload} />
          </Box>
        </Flex>
      )}
    </>
  );
};
