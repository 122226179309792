import React, { useEffect } from "react";
import { Box, Container, Flex } from "@mantine/core";
import { NavBar } from "./NavBar";
import Footer from "./Footer";

function Layout({ children }: { children: React.ReactNode }) {
  const [opened, setOpened] = React.useState<boolean>(false);

  const toggleMenu = () => {
    setOpened(!opened);
  };

  const handleClick = () => {
    setOpened(false);
  };

  return (
    <Box className="bg-bg p-0 min-h-screen m-0 selection:bg-secondaryAccent">
      <Container className="top-0 font-barlowFont" size="lg">
        <Flex direction="column">
          <Box className="top-0 sticky z-10 bg-bg">
            <NavBar
              opened={opened}
              handleToggle={toggleMenu}
              handleClick={handleClick}
            />
          </Box>
          <Box className={opened ? "hidden" : "block"}>{children}</Box>
          {/*<Footer />*/}
        </Flex>
      </Container>
    </Box>
  );
}

export default Layout;
